<template>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="6" md="6">
            <v-card class="elevation-12">
              <v-card-text class="mt-12">
                <h1
                    class="text-center display-2 greg text--accent-3"
                ><v-img
                    :src="require('../assets/Connexion@2x-100.jpg')"
                    contain
                    max-height="110"
                    offset-md="4"
                /></h1>
                <v-form>
                  <v-row align="center" justify="center">
                    <v-col cols="12" sm="6">
                      <v-text-field
                          label="Email"
                          name="Email"
                          prepend-icon="email"
                          type="text"
                          color="#14ad95"
                          v-model.trim="email"
                      />

                      <v-text-field
                          id="password"
                          label="Mot de passe"
                          name="password"
                          prepend-icon="lock"
                          type="password"
                          color="#14ad95"
                          v-model.trim="password"
                          v-on:keyup.enter="login"
                      />
                    </v-col>
                  </v-row>

                </v-form>
                <h3 class="text-center mt-4">Mot de passe oublié ?</h3>
              </v-card-text>
              <div class="text-center mt-3 pb-5">
                <v-btn rounded color="#14ad95" dark @click.prevent="login" :loading="isLoading">Se connecter</v-btn>
              </div>
              <div v-if="hasErrors">
                <v-alert
                    v-for="error in errors" :key="error.id"
                    dismissible
                    dark
                    type="error"
                >
                  Mot de passe ou adresse mail incorrect
                </v-alert>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="footer-greg">
          <v-img
              :src="require('../assets/Feuilles-connexion@2x-100.jpg')"
              contain
              max-height="174"
              min-width="1200"
              offset-md="4"
          />
        </v-row>
      </v-container>
</template>

<script>
import firebase from "firebase/compat";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile } from "firebase/auth"
export default {
  name: "Login",
  data() {
    return {
      step: 1,
      loading: true,
      auth: getAuth(),
      firstname: "",
      email: "",
      password: "",
      password_confirmation: "",
      errors: [],
      usersRef: firebase.database().ref("users"),
      isLoading: false,
    };
  },
  props: {
    source: String,
  },
  computed: {
    hasErrors: function() {
      return this.errors.length > 0
    },
  },
  methods: {
    switchToCreateAccount: function () {
      this.mode = 'create';
    },
    switchToLogin: function () {
      this.mode = 'login';
    },
    login: function () {
      //const provider = new firebase.auth.GoogleAuthProvider()
      //firebase.auth().signInWithRedirect(provider)
      // firebase.auth().signInWithEmailAndPassword()
      //     .catch(error => console.log(error))
      this.errors = []
      if(this.isLoginFormValid()){
        this.isLoading = true
        signInWithEmailAndPassword(this.auth, this.email, this.password)
            .then((userCredential) => {
              // Signed in
              const user = userCredential.user;
              console.log(user);
              this.$store.dispatch('setUser', user)
              this.$router.push('/')
            })
            .catch((error) => {
              this.errors.push(error.message)
              this.isLoading = false
              const errorCode = error.code;
              console.log("error1"+errorCode);
              // const errorMessage = error.message;
              console.log(this.hasErrors);

            });

      }
    },
    create: function () {
      const auth = getAuth();
      createUserWithEmailAndPassword(auth, this.email, this.password)
          .then((userCredential) => {
            const user = userCredential.user;
            // Signed in
            console.log(user);
            updateProfile( user, {
              displayName: this.firstname,
              photoURL: "https://pixabay.com/get/g34a6e36c4750d6244d0b9eb21632450d808c2e469bd65bc53b2638311713e0f0c1c78e33ef35eb7a441cb7c89764faa46258b937fb1d8d0118b4869cd670c1da_1280.png"
            }).then ( () => {
              //Enregistrement de l'utilisateur en bdd
              this.saveUserToUsersRef(user).then(() => {
                this.$store.dispatch("setUser", user)
                this.$router.push("/dashboard");
              })
            },error => {
              console.log(error)
              this.errors.push(error.message)
              this.isLoading = false
            })
          })
          .catch((error) => {
            const errorCode = error.code;
            console.log(errorCode);
            const errorMessage = error.message;
            console.log(errorMessage);
          });
    },
    saveUserToUsersRef(user){
      return this.usersRef.child(user.uid).set({
        name: user.displayName,
        avatar: user.photoURL
      })
    },
    isEmpty: function () {
      if (this.email.length === 0 && this.firstname.length === 0 && this.lastname.length === 0 && this.password.length === 0 && this.password_confirmation.length === 0) {
        return true;
      } else {
        return false;
      }
    },
    passwordValid: function () {
      if (this.password.length < 6 || this.password_confirmation.length < 6) {
        return false;
      }
      if (this.password !== this.password_confirmation) {
        return false;
      }
      return true;
    },
    isFormValid: function () {
      if (this.isEmpty()) {
        this.errors.push("Veuillez remplir tous les champs");
        return false;
      }
      if(!this.passwordValid()){
        this.errors.push("Mot de passe incorrects");
        return false;
      }
      return true;
    },
    isLoginFormValid(){
      if(this.email.length > 0 && this.password.length > 0){
        return true
      }
      return false
    }
    }
};
</script>
<style>
.greg{
  color: #14ad95;
}
.bg-fad{
  background-color: #14ad95;
}
.footer-greg{
  position: absolute;
  bottom: 1.7%;
}

@media screen and (max-width: 400px) {
  .footer-greg{
    display:none;
    clear:both;
  }
}
</style>
